html {
  min-width: 320px;
  background-color: #d9d9d9;
  background-image: url(./img/bg.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-attachment: fixed;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline;
}

p {
  overflow-wrap: break-word;
}

.container {
  width: 100%;
  min-height: 100vh;
  max-width: 860px;
  margin: 0 auto;
  background-color: #efefef;
  padding: 32px 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

.page-title {
  margin-top: 0;
  font-size: 16px;
  display: inline-block;
  text-decoration: underline;
  margin-right: 16px;
}

.jump-link {
  color: #0000ee;
  cursor: pointer;
  text-decoration-color: currentColor;
  text-decoration: underline;
}
.border-hr {
  margin-top: 16px;
}
.title {
  margin-top: 0;
  color: #fe0300;
  font-size: 18px;
  font-weight: 300;
  padding: 20px 0;
}
.item small {
  color: gray;
}
.item .name {
  font-weight: bold;
  color: #008000;
}
.item .name.sage {
  color: #0000ee;
  cursor: pointer;
  text-decoration: underline;
}
.item .count {
  color: #008000;
}
dd {
  margin-right: 28px;
}
.purchase-area {
  width: 314px;
}

.purchase-area img {
  width: 100%;
}
.nft-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.nft-title {
  font-weight: bold;
}
.nft-price {
  color: #fe0300;
}
.nft-remaining {
  font-size: 12px;
  color: gray;
}
.order-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.order-input {
  width: 45%;
}
.button {
  width: 100%;
  padding: 8px 0;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: opacity 0.3s;
}
.button:disabled {
  background-color: #b6b6b6;
  cursor: default;
}
.button:hover {
  opacity: 0.8;
}
.button:disabled:hover {
  opacity: 1;
}
.button-purchase {
  color: #fff;
  background-color: #008000;
}
.button-error {
  color: #fff;
  background-color: #fe0300;
}
.button-error-message {
  color: #fe0300;
  text-align: center;
  font-size: small;
}
.button-success {
  color: #fff;
  background-color: #008000;
}
.button-success-message {
  color: #008000;
  text-align: center;
  font-size: small;
}
.share-button {
  width: 100%;
  max-width: 314px;
  color: #fff;
  background-color: #1da1f2;
  padding: 8px 0;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  transition: opacity 0.3s;
}
.share-button:hover {
  opacity: 0.8;
}
.twitter-logo {
  width: 16px;
  padding-right: 8px;
}
.connect-wallet-button,
.metamask-app-button {
  width: 100%;
  max-width: 314px;
  padding: 8px 0;
  color: #000;
  border: 1px solid #000;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  transition: opacity 0.3s;
}
.connect-wallet-button:hover,
.metamask-app-button:hover {
  opacity: 0.4;
}

@media screen and (max-width: 424px) {
  dd {
    margin-right: 0;
  }
  .purchase-area {
    width: 100%;
  }
  .connect-wallet-button,
  .metamask-app-button {
    max-width: none;
  }
}
